.navbar{
    padding: 0px;
}

.site-footer{
    background-image: url('img/footer-bg.png');
}

.hero-slider{
    background-image: url(img/main-bg.jpg);
}

.mobile-menu-toggle.active .icon-menu::before{
    content: "\ea04" !important;
}

.search-box .input-group-btn, .mobile-search .input-group-btn{
    z-index: 4;
}

.not-found{
    background-image: url('img/404-bg.png');
}

.pagination-info{
    word-spacing: 6px;
}

.widget-light-skin a{
    transition: opacity 0.25s;
    color: #ffffff;
    text-decoration: none;
}

.hidden{
    display: none;
}
.row.row-products > div{
    display: flex;
}

.product-card{
    display: flex;
    flex-direction: column;
}

.product-card .product-card-body{
    flex: 1 0 auto;
}

.product-card .product-thumb > img{
    height: 100%;
    object-fit: contain;
}

.product-price-large{    
    background: #fff6e3;
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
}

.map{
    padding: 0px;
    border: 1px solid #e0e0e0;
    position: relative;
    height: 400px;;
    overflow: hidden;
}
.map.is-invalid{
    border-color: #dc3545 !important;
}
.react-tel-input.is-invalid ~ .invalid-feedback,
.map.is-invalid ~ .invalid-feedback {
    display: block;
}


.map.is-invalid ~ .invalid-feedback {
    display: block;
}
.alert.invalid-feedback{
    padding: .75rem 1.25rem;
}
.hero-slider.no-background{
    background-image: none;
}

.alert-demo, .alert-demo a{
    color: white;
    background-color: #0055ff;
}
.alert-demo a{
    text-decoration: underline;
}

.react-tel-input .selected-flag{
    border-radius: 5px 0 0 5px;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    padding: 0;
    border-radius: 5px;
    border-width: 0px;
}

.react-tel-input .form-control{
    width: 100%;
    height: 46px;
}

.react-tel-input .country-list .search-box{
    padding: 10px 8px 10px 35px;
}

.user-cover{
    background-image: url(img/user-cover-img.jpg);
}

.product-card .discount-alert{
    margin-bottom: 0px !important;
}

.discount-alert{
    font-size : 10px;
    padding: 15px;
    text-align: justify;
}
.discount-alert p{
    margin-bottom: 10px !important;
}

.modal-90w {
    width: 95%;
    max-width: none!important;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    
    .modal-90w {
        width: 70%;
        max-width: none!important;
    }

 }

 .custom-radio, .labelPayment{
     cursor: pointer;
 }

/*------ LOADING INDICATOR --------------*/
 .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #9a9a9a;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  /*------END LOADING INDICATOR--------------*/